import { useMemo, useState } from 'react'
import { FaChevronDown } from 'react-icons/fa'

import { Divider } from 'src/components/atoms/Divider'
import { OnClickLink } from 'src/components/atoms/Link'
import { StandaloneHeading } from 'src/components/layouts/Page/SanityContentPage/StandaloneHeading'
import { FaqSectionResult } from 'src/components/layouts/Page/SanityContentPage/types'

import { ArrayElement } from 'src/types/common'

import { Content } from '../sanity/Content'

type FaqSectionProps = FaqSectionResult

type FaqItemProps = ArrayElement<NonNullable<FaqSectionResult['faqItems']>> & {
  color: FaqSectionResult['backgroundColor']
}

const FaqItem = ({ question, answer, color }: FaqItemProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const borderClasses = useMemo(() => {
    if (isOpen) {
      return 'border-mostlyGreen'
    }
    switch (color) {
      case 'dark':
        return 'border-mostlyGreen'
      case 'light':
        return 'border-aquaSpring'
      case 'white':
      default:
        return 'border-superSilver'
    }
  }, [color, isOpen])

  return (
    <div className={`border ${borderClasses} rounded-[5px] bg-white`}>
      <OnClickLink
        className='!text-charcoal px-5 py-[15px] md:p-5 active:!text-[#C32C32] focus:!text-[#C32C32] hover:!text-primary flex flex-row gap-[10px] justify-between items-center text-[16px] lg:text-[18px] leading-[22px] font-semibold'
        onClick={() => {
          setIsOpen(p => !p)
        }}
      >
        <span>{question}</span>
        <FaChevronDown
          className={`flex-shrink-0 inline-block duration-300 transition-transform ${
            isOpen ? '-rotate-180' : ''
          }`}
        />
      </OnClickLink>
      {isOpen ? (
        <div className='flex flex-col gap-[15px] md:gap-5 p-5 pt-0'>
          <Divider className='!m-0' />
          <div className='whitespace-pre-wrap text-[16px] leading-[24px] font-medium'>
            <Content body={answer} />
          </div>
        </div>
      ) : null}
    </div>
  )
}

const FaqSection = ({
  backgroundColor = 'dark',
  faqItems,
  heading
}: FaqSectionProps) => {
  const classes = useMemo(() => {
    switch (backgroundColor) {
      case 'dark':
        return { container: 'bg-mostlyGreen', heading: 'text-white' }
      case 'light':
        return { container: 'bg-aquaSpring', heading: 'text-charcoal' }
      default:
        return { container: 'bg-white', heading: 'text-charcoal' }
    }
  }, [backgroundColor])

  return (
    <div
      className={`flex flex-col gap-5 md:gap-10 p-4 md:p-10 rounded-[10px] ${classes.container}`}
    >
      <StandaloneHeading
        mode={backgroundColor === 'dark' ? 'dark' : 'light'}
        className='!mb-0'
        {...heading}
      />
      <div className='flex flex-col gap-5'>
        {faqItems?.map(item => (
          <FaqItem key={item._key} color={backgroundColor} {...item} />
        ))}
      </div>
    </div>
  )
}

export default FaqSection
